import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';

export const environment: EnvironmentsEnum = EnvironmentsEnum.devnet;

export const contractAddress =
  'erd1qqqqqqqqqqqqqpgqstmt3l73fyqt087p5cdhtvmc2yhyfsr0yl5sauaw4w';

export const dAppName = 'Refracto';
export const NUMBER_OF_SHARES_PER_PROJECT = 1_000_000;

export const toLocaleStringOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

export const toLocaleStringOptionsNoDecimals = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};

// TODO: generate a new wallet connect id here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';
export const relayUrl = 'wss://relay.walletconnect.com';
export const walletConnectDeepLink =
  'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://xportal.com/';
export const supabaseConfig = {
  url: 'https://ovzvepwxbmkdpomlubgd.supabase.co',
  // url: 'http://localhost:54321',
  anonKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im92enZlcHd4Ym1rZHBvbWx1YmdkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODkxNjY3NjUsImV4cCI6MjAwNDc0Mjc2NX0.zYKGpyf8FAYg0zN7mAmmCQu0EU6B5w_jYFEQdJ4cktk'
};

export const DEMO_USDC_FAUCET_SC_ADDRESS =
  'erd1qqqqqqqqqqqqqpgqxz445g0f6l6ryz7dkhlcpkc4752rvawmyl5s30psm0';

export const USDC_TOKEN_ID = 'RUSDC-881827';
export const INTEREST_RATE_DENOMINATION = 1_000_000_000;
export const ONE_DENOMINATED_USDC = 1_000_000;
export const REFRACTO_LOAN_SHARE_TOKEN_ID = 'REFRACTO-b25fa1';

// Demo only :)
export const TEMP_ADMIN_WALLETS = [
  'erd1j43ssgjr8x9m0s7zz0xhn7qdgssdetm86jc6a2dzsegs74fmyl5ssv44c4'
];
